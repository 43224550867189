import { lang } from "./config";
const langLib = {
  // adv_list
  live_marketing: ["直播营销", "Live Marketing"],
  advertisement_placement: ["广告投放", "Advertisement Placement"],
  sell_goods: ["直播带货", "Sell goods through livestreaming"],
  tong_tou: ["通投广告", "Tongtou Advertising"],
  new_plan: ["新建计划", "New Plan"],
  del: ["删除", "Delete"],
  money: ["推广余额", "Promotion Balance"],
  all: ["全选（已选0个）", "Select All(select 0)"],
  name: ["计划名称", "Plan Name"],
  offer: ["出价（元）", "Offer"],
  budget: ["预算", "Budget"],
  operate: ["操作", "Operate"],
  edit: ["编辑", "Edit"],
  delete: ["删除", "Delete"],
  //   create_adv
  live_marketing: ["直播营销", "Live marketing"],
  advertisement_placement: ["广告投放", "Advertisement placement"],
  create_plan: ["创建计划", "Create Plan"],
  launch_settings: ["投放设置", "Launch Settings"],
  delivery_method: ["投放方式", "Delivery method"],
  control_investment: ["控成本投放", "Cost control investment"],
  maximize: ["优先控制成本达标,最大限度使用广告预算", "Prioritize controlling costs to meet standards and maximize the use of advertising budgets"],
  ceiling: ["严格控制成本上限", "Strictly control the cost ceiling"],
  speed: ["投放速度", "Release speed"],
  soon: ["尽快投放", "Quickly"],
  placement: ["均匀投放", "Even"],
  content1: ["如遇合适流量，预算集中投放", "If appropriate traffic is encountered, budget will be centrally allocated"],
  content2: ["全天预算平滑投放", "Smooth budget deployment throughout the day"],
  conversion: ["转换目标", "Conversion"],
  enter_live: ["进入直播间", "Enter the live broadcast room"],
  more_people: ["更多人进入直播间", "More people entering the live broadcast room"],
  time_market: ["投放时间", "Time to market"],
  only_live: ["所选时间范围内，仅直播时进行投放", "Within the selected time range, only live streaming is available for advertising"],
  fixed_period: ["固定时段", "Fixed Time"],
  clear: ["清空", "Clear"],
  budget: ["预算", "Budget"],
  amount: ["请输入金额", "Please enter the amount"],
  rmb: ["元", "RMB"],
  offer: ["出价", "Offer"],
  price: ["请输入价格", "Please enter the price"],
  display_times: ["元/展现次数", "RMB/Display Times"],
  population: ["定向人群", "Targeted population"],
  region: ["地域", "Region"],
  no_limit: ["不限", "No limit"],
  province_city: ["根据省市", "According to the province and city"],
  sex: ["性别", "Sex"],
  man: ["男", "Man"],
  woman: ["女", "Woman"],
  age: ["年龄", "Age"],
  interest: ["行为兴趣意向", "Behavioral interest intention"],
  custom: ["自定义", "Custom"],
  please_select: ["请选择", "Please select"],
  network: ["网络", "Network"],
  platform: ["平台", "Platform"],
  more_people: ["更多人群", "More people"],
  peoples: ["更多人群", "Mores"],
  platform_selection: ["平台精选", "Platform Selection"],
  sensitive_groups: ["促销敏感人群", "Promotional Sensitive Groups"],
  high_order: ["高订单取消/退货人群", "High order cancellation/return population"],
  attention_group: ["高关注数人群", "High attention group"],
  active_population: ["高活跃人群", "Highly active population"],
  tiktok: ["抖音号粉丝", "Tiktok fans"],
  directional: ["定向", "Directional"],
  exclude: ["排除", "Exclude"],
  creativity: ["请为直播间增加创意", "Please add creativity to the live broadcast room"],
  labels: ["创意标签", "Creative labels"],
  maximum_label: ["请输入标签，长度最多十个字符", "Please enter a label with a maximum length of ten characters"],
  add: ["添加", "Add"],
  selectone: ["全选", "Select All"],
  selecttwo: ["已选", "Selected"],
  plan_name: ["计划名称", "Plan name"],
  input_plan: ["请输入计划名称(2/50)", "Please enter the plan name (2/50)"],
  agree: ["我已阅读并同意", "I have read and agree"],
  commitment: ["《直播间引流承诺函》", "《Commitment Letter for Live Room》"],
  cancel: ["取消", "Cancel"],
  elease: ["发布计划", "Release Planning"],
  first_tier: ["一线城市", "First tier city"],
  new_first: ["新一线城市", "New first tier cities"],
  second_tier: ["二线城市", "Second tier cities"],
  third_tier: ["三线城市", "Third tier city"],
  fourth_tier: ["四线城市", "Fourth tier cities"],
  fifth_tier: ["五线城市", "Fifth tier cities"],
  input_msg: ["请正确输入", "Please input correctly"],
  plan_msg: ["计划名称最小2个字，最多50个字", "Plan name must have a minimum of 2 words and a maximum of 50 words"],
  stimated_orientation: ["预估定向", "Estimated Orientation"],
  number_advertising: ["广告展示数", "Number of advertising"],
  thousand: ["万", "RMB"],
  coverage: ["用户覆盖数", "User coverage"],
  selected_directional: ["已选定向信息", "Selected directional information"],
  // market data
  market_data: ["市场数据", "Market Data"],
  traffic: ["行业分类流量占比", "Industry classification traffic proportion"],
  age_release: ["年龄分布", "Age Distribution"],
  two_class: ["二级类目", "Class II"],
  proportion: ["占比", "Proportion"],
  gender_distribution: ["性别分布", "Gender distribution"],
  echarts_man: ["男性", "Man"],
  echarts_woman: ["女性", "Woman"],
  active_time: ["活跃时间分布", "Active time distribution"],
  activation: ["活跃度", "Activation"],
  ranking: ["排名", "Rank"],
  city: ["城市", "City"],
  high: ["高", "High"],
  low: ["低", "Low"],
  equipment: ["设备分布", "Equipment distribution"],
  ios: ["ios", "ios"],
  android: ["安卓", "Android"],
  wherein: ["其中", "Wherein"],
  network_distribution: ["网络分布", "Network distribution"],
  population_distribution: ["人群分布", "Population distribution"],
  // echarts
  audience_source: ["观众来源", "Audience source"],
  years_old: ["岁", "Years Old"],
  lucky_bag: ["福袋", "Lucky Bag"],
  coupon: ["优惠券", "Coupon"],
  bag_title: ["福袋标题", "Bag Title"],
  bags: ["福袋个数", "Number of bags"],
  countdown: ["倒计时", "Countdown"],
  participation: ["参与范围", "Participation"],
  participation_password: ["参与口令", "Participation password"],
  winner: ["已中奖人", "Winner"],
  no_data: ["暂无数据", "No Data"],
  only_fans: ["仅限粉丝", "OnlyFans"],
  all_Viewers: ["所有观众", "All Viewers"],
  minute: ["分钟", "Minute"],
  second: ["秒", "Second"],
  offer_Name: ["优惠卷名称", "Offer Name"],
  trade: ["商品名称", "Trade name"],
  coupon_type: ["优惠券类型", "Coupon type"],
  distribution_volume: ["发放量", "Distribution volume"],
  received: ["已领取", "Received"],
  please_select: ["请选择", "Please select"],
  reduction_coupon: ["指定商品满减卷", "Spend reduction coupon"],
  discount_coupon: ["指定商品折扣卷", "Discount coupon"],
  sheets: ["张", "Sheets"],
  product_list: ["商品列表", "Product list"],
  sorting: ["上架时间排序", "Listing time sorting"],
  sales_sorting: ["销售额排序", "Sales sorting"],
  Number: ["序号", "Number"],
  information: ["商品信息", "Commodity information"],
  live_room_price: ["直播间价格", "Price"],
  click_rate: ["点击率", "Click Rate"],
  conversion_rate: ["转化率", "Conversion Rate"],
  orders: ["订单数", "Orders Number"],
  sales_volume: ["销售额", "Sales volume"],
  now_sales: ["销量", "Sales"],
  room_likes: ["直播间点赞", "Live room likes"],
  total: ["累计", "Total"],
  total_likes: ["总点赞数", "Total likes"],
  like_count: ["点赞数", "Like count"],
  unit: ["单位（w）", "Unit (w)"],
  now_likes: ["点赞量", "Likes"],
  online_users: ["在线人数", "Number of online users"],
  commerce: ["电商数据主屏", "E-commerce data main screen"],
  full: ["全屏", "Full"],
  streaming: ["直播中", "Live streaming"],
  broadcasting: ["未开播", "Not Live"],
  airtime: ["开播时间", "Airtime"],
  already: ["累计直播时长：已直播", "Accumulated live broadcast duration: already live"],
  amount_rmb: ["直播期间累计成交金额(元)", "Current live streaming cumulative transaction amount (RMB)"],
  deal: ["成交件数", "Number of transactions"],
  products: ["上架商品", "Listing products"],
  highest: ["单品最高销量", "The highest sales volume of a single product"],
  new_fans: ["新增粉丝数", "Number of new fans"],
  real_time: ["实时在线人数", "Real time online population"],
  average: ["平均客单价", "Average unit price"],
  maximum_sales: ["单品最高销售额", "Maximum sales revenue per item"],
  accumulated: ["累计观看人数", "Accumulated number of viewers"],
  Barrage: ["弹幕词云", "Barrage"],
};
export default lang(langLib);
